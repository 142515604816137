"use strict";

export default {
    "methods": {
        $_pagelink() {
            this.$nextTick(() => {
                const refName = this.$route.hash.replace("#", "");
                if (refName) {
                    setTimeout(() => {
                        this.$refs[refName].scrollIntoView({ "behavior": "smooth" });
                    }, 240);
                }
            });
        },
    },
};

<template>
    <div class="contents">
        <h1 class="pagetitle">特定商取引法に基づく表記</h1>
        <table class="l-mt4">
            <tr>
                <th>販売事業者</th>
            </tr>
            <tr>
                <td>株式会社葵フーズディナーズ</td>
            </tr>
            <tr>
                <th>運営統括責任者</th>
            </tr>
            <tr>
                <td>髙木智康</td>
            </tr>
            <tr>
                <th>所在地</th>
            </tr>
            <tr>
                <td>愛知県名古屋市天白区植田南三丁目104</td>
            </tr>
            <tr>
                <th>連絡先</th>
            </tr>
            <tr>
                <td>
                    電話番号：<a href="tel:0528033633">052-803-3633</a><br>
                    メールアドレス：<a href="mailto:aoishop@aoifoods.co.jp">aoishop@aoifoods.co.jp</a><br>
                    受付時間：9：00～11：59・13：00～17：00（休業日・土日 年末年始 夏季 その他）
                </td>
            </tr>
            <tr ref="productconditions">
                <th>商品条件</th>
            </tr>
            <tr>
                <td>
                    単品商品につきましては包装・熨斗のご対応はできませんのでご了承ください。<br>セット商品につきましては当社指定包装紙にて包装した状態でのお届けとなります。<br>セット商品の熨斗をご希望な場合は、ご注文の際コメント欄へ詳細をご入力ください。<br>ご対応可能な熨斗は次の通りとなります。
                    <ul class="list">
                        <li class="list__item">熨斗種類＝蝶結びのみ ※熨斗は当社指定の物に限ります。</li>
                        <li class="list__item">のし上＝無地／お中元／お歳暮／御礼／御祝 のみ</li>
                        <li class="list__item">名入れ＝ご対応いたしておりません。</li>
                        <li class="list__item">内外＝内熨斗のみ（包装の内側に熨斗を貼付）</li>
                    </ul>
                    また、熨斗をご希望の上、詳細のご入力の無い場合は無地熨斗にてご用意いたします。
                </td>
            </tr>
            <tr>
                <th>お支払方法</th>
            </tr>
            <tr>
                <td>
                    お支払いは、前払いの銀行振込、代金引換、からお選び頂けます。
                    <ul class="list">
                        <li class="list__item">銀行振込（前払い・振込手数料はお客様負担）<br>三菱UFJ銀行 平針支店 普通 1529084 「株式会社葵フーズディナーズ」</li>
                        <li class="list__item">代金引換（ヤマトコレクト便・代引手数料は弊社負担）</li>
                    </ul>
                </td>
            </tr>
            <tr>
                <th>お支払い時期</th>
            </tr>
            <tr>
                <td>
                    <ul class="list">
                        <li class="list__item">銀行振込の場合は、注文確定後10日以内に弊社指定銀行口座にご入金ください。10日以内にご入金のない場合、お客様がご注文を取り消したものとみなします。入金の確認が取れない場合はご注文キャンセルのご連絡をさせていただきます。</li>
                        <li class="list__item">代金引換の場合は、お客様への配達時に運送業者へお支払いください。</li>
                    </ul>
                </td>
            </tr>
            <tr>
                <th>商品代金以外の必要料金</th>
            </tr>
            <tr>
                <td>
                    送料は、一回のお買物につきご購入合計金額により下記の通りとなります。
                    <ul class="list">
                        <li class="list__item">合計金額が10,000円（税込）以上の場合<br>北海道1,100円（税込）／沖縄1,650円（税込）／その他の地域は無料</li>
                        <li class="list__item">合計金額が10,000円（税込）未満の場合<br>北海道1,925円（税込）／沖縄2,475円（税込）／その他の地域825円（税込）</li>
                    </ul>
                    ギフト包装・熨斗は無料で承ります。（但し当社指定のギフト箱・包装紙・熨斗に限ります。）
                </td>
            </tr>
            <tr>
                <th>商品引渡時期</th>
            </tr>
            <tr>
                <td>
                    <ul class="list">
                        <li class="list__item">代金引換の場合、ご注文確定後5営業日以内に発送いたします。</li>
                        <li class="list__item">銀行振込（前払い）の場合、ご入金確認後5営業日以内に発送いたします。</li>
                    </ul>
                    発送後のお届け所要日数は地域によって異なります。<br>
                    ※ご注文いただきました商品が在庫切れの場合は、ご連絡差し上げます。<br>
                    なお、配送は日本国内のみとさせていただきます。
                </td>
            </tr>
            <tr>
                <th>ご注文の取り消し</th>
            </tr>
            <tr>
                <td>
                    ご注文確定後のキャンセルはお受けできません。予めご了承ください。
                </td>
            </tr>
            <tr>
                <th>返品・交換</th>
            </tr>
            <tr>
                <td>
                    ＜当社原因の返品・交換について＞<br>
                    商品の品質・梱包について万全を期しておりますが、万が一「商品違い」「数量違い」「不良」等があった際は、到着日から1週間以内にご連絡ください。弊社送料負担にて、代替品と交換させていただきます。<br>
                    連絡先：<a href="tel:0528033633">052-803-3633</a> 株式会社葵フーズディナーズ<br>
                    ＜お客様都合の返品・交換について＞<br>
                    お客様都合での返品・交換はお受けできませんので、予めご了承ください。
                </td>
            </tr>
        </table>
        <!-- <div class="l-mt10 l-mb2 is-center">
            <button class="button--oval--cancel" type="button" @click="$_goBack()">戻る</button>
        </div> -->
    </div>
</template>

<script>
    "use strict";

    import common from "@/mixins/common";
    import pagelink from "@/mixins/pagelink";

    export default {
        "mixins": [
            common,
            pagelink,
        ],
        "props": {
            "contents": {
                "required": true,
                "type": Object,
            },
        },
        mounted() {
            this.$_pagelink();
        },
    };
</script>
